export default {
  colors: {
    primary: '#8257E6',
    background: '#121214',
    shape: `#202024`,
    title: `#E1E1E6`,
    text: `#A9A9B2`,
    components: {
      blockquote: {
        background: `#633BBC`,
        text: `#E1E1E6`,
      },
    },
  },
  fonts: {
    body: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
  },
};